import { useNavigate } from "react-router-dom"
import { useAuth } from "../../domain/contexts/auth-context"

export type HeaderProps = {

}

const LtHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
    const navigate = useNavigate()
    const auth = useAuth()


    const openCirculoAutores = () => {
        var url = "https://www.literatour.com.br/livro-digital-do-mes/";
        window.open(url, "_blank");
    }


    const openLiteratourFaq = () => {
        var url = "https://www.loja.literatour.com.br/duvidas-frequentes/";
        window.open(url, "_blank");
    }

    const logout = () => {
        auth.logOut()
        navigate('/login')

    }

    return (

        <section className="lt-xp-header hero is-primary is-medium is-5">
            
            <div className="hero-head">
                <nav className="navbar lt-navbar">
                    <div>
                    <img className="lt-logo-header"   src="../../../assets/literatour-branco-sem-fundo.png" alt="Logo Literatour branco sem fundo" />

                    </div>

                    <div className='lt-header-profile-link' onClick={logout}>
                        SAIR
                        <span className="icon-text">
                            <span className="icon">
                                <a>
                                    <i className="fa-solid fa-right-from-bracket"></i>

                                </a>
                            </span>
                        </span>
                    </div>



                </nav>
            </div>


            <div id='lt-header-menu-items' className="hero-foot">
                <nav className="tabs">
                    <div className="container">
                        <ul >
                           
                            <li className="mr-1" ><button className="button is-dark" onClick={() => navigate('/')}>
                                <span className="icon is-small m-0">
                                    <img className="header-logo-min" width='20px' height='16px' src="../../../assets/logo_no_bg.png" alt="Logo" />
                                    </span>
                                <span className="lt-menu-item-description">Home</span>
                            </button></li>
                            <li className="mr-1"><button className="button is-dark" onClick={openCirculoAutores}>
                                <span className="icon is-small m-0">
                                    <i className="fa-solid fa-book"></i>                                </span>
                                <span className="lt-menu-item-description"> Círculo de Autores</span>
                            </button></li>
                            <li className="mr-1"><button className="button is-dark" onClick={() => navigate('/usuario')}>
                            <span className="icon is-small m-o">
                            <i className="fa-solid fa-user"></i>                                </span>
                                <span className="lt-menu-item-description">Meu perfil</span>
                            </button></li>
                            <li className="mr-1"><button className="button is-dark" onClick={() => navigate('/assinatura')}>
                                <span className="icon is-small m-o">
                                    <i className="fa-solid fa-info"></i>                                </span>
                                <span className="lt-menu-item-description">Minha Assinatura</span>
                            </button></li>
                            <li className="mr-1"><button className="button is-dark" onClick={openLiteratourFaq}>
                                <span className="icon is-small m-o">
                                    <i className="fa-solid fa-question"></i>                                </span>
                                <span className="lt-menu-item-description">Ajuda</span>
                            </button></li>
                            <li className="mr-0 lt-logout-desktop-icon"><button className="button is-dark" onClick={logout}>
                                <span className="icon is-small m-0">
                                <i className="fa-solid fa-right-from-bracket"></i>
                                <span className="lt-menu-item-description">Sair</span>
                                </span>
                            </button></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>


    )
}

export default LtHeader
