import { Container } from '@mui/material';
import AuthProvider from './domain/contexts/auth-context';

import './App.css';
import LtHeader from './view/components/header';
import { useNavigate } from 'react-router-dom';


function App() {

  const navigate = useNavigate()

  return (
  <>
      <LtHeader />
      <Container maxWidth="sm">
        <AuthProvider>

          <div className="card">
            <div className="card-content">
              <p className="title">Portal Literatour</p>
              <div className='content'>
                <i>Versão beta 0.1 </i> <br></br>
                Em caso de dificuldades no uso da plataforma, por favor entre em contato.
              </div>
              <div className="buttons">
                  <button className="button is-primary is-fullwidth "  onClick={() => navigate('/usuario')}>Alterar preferências</button>
                  <button className="button is-link is-fullwidth" onClick={() => navigate('/assinatura')}>Avaliar minha última caixinha</button>
                </div>
            </div>
          <footer className="card-footer">
              <p className="card-footer-item">
                <span>
                  Comunidade oficial no  <a href="https://chat.whatsapp.com/GkJHAMuQfwd2anoX2afzsx">WhatsApp</a>
                </span>
              </p>
              <p className="card-footer-item">
                <span> Página no <a href="https://www.instagram.com/literatourclube/">Instagram</a> </span>
              </p>

              <div>
              </div>
            </footer>
          </div>         </AuthProvider>
      </Container>
    </>

  );
}

export default App;
