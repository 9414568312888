import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import UserPage from './view/pages/user/UserPage';
import SubscriptionPage from './view/pages/subscription/SubscriptionPage';
import TrophiesPage from './view/pages/trophies/TrophiesPage';
import BookshelfPage from './view/pages/bookshelf/Bookshelf';
import BookSuggestionPage from './view/pages/books-suggestion/BookSuggestion';
import { makeUserService } from './domain/factories/services/user-service-factory';
import { makeCategoryService } from './domain/factories/services/category-service-factory';
import LoginPage from './view/pages/login/LoginPage';
import RegisterPage from './view/pages/login/RegisterPage';
import RecoverPasswordPage from './view/pages/login/RecoverPassword';
import PrivateRoute from './routes/private-router';
import AuthProvider from './domain/contexts/auth-context';
import { makeSubscriptionService } from './domain/factories/services/subscription-service-factory';
import { makeBookSuggestionService } from './domain/factories/services/book-suggestion-service-factory';
import { makeFeedbackTypeService } from './domain/factories/services/feedback-type-service-factory';
import { makeKitService } from './domain/factories/services/kit-service-factory';
import { makeAuthenticatorService } from './domain/factories/services/authenticator-service-factory';
import { makeEmailService } from './domain/factories/services/email-service-factory';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider><PrivateRoute><App /></PrivateRoute></AuthProvider>,
  },
  {
    path: "login",
    element: <AuthProvider><LoginPage /></AuthProvider>

  },
  {
    path: "primeiro-acesso",
    element:<AuthProvider><PrivateRoute> <RegisterPage authService={makeAuthenticatorService()} emailService={makeEmailService()} /></PrivateRoute></AuthProvider>

  },
  {
    path: "esqueci-a-senha",
    element: <AuthProvider><RecoverPasswordPage /></AuthProvider>

  },
  {
    path: "usuario",

    element: <AuthProvider><PrivateRoute><UserPage service={makeUserService()} subscriptionService={makeSubscriptionService()} categoryService={makeCategoryService()} /></PrivateRoute></AuthProvider>
  },
  {
    path: "assinatura",
    element: <AuthProvider><PrivateRoute><SubscriptionPage service={makeSubscriptionService()} feedbackTypeService={makeFeedbackTypeService()} kitService={makeKitService()} /></PrivateRoute></AuthProvider>
  },
  {
    path: "conquistas",
    element: <AuthProvider><PrivateRoute><TrophiesPage /></PrivateRoute></AuthProvider>

  },
  {
    path: "estante",
    element: <AuthProvider><PrivateRoute><BookshelfPage /></PrivateRoute></AuthProvider>
  },
  {
    path: "sugestao-leitura",
    element: <AuthProvider><PrivateRoute><BookSuggestionPage service={makeBookSuggestionService()} categoryService={makeCategoryService()} /></PrivateRoute></AuthProvider>

  }


]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ToastContainer />
    <AuthProvider> <RouterProvider router={router} /></AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
