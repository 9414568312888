
export type TrophiesPageProps = {

}

const TrophiesPage: React.FC<TrophiesPageProps> = (props: TrophiesPageProps) => {
  
  //const { possuiRoles } = useContext(UsuarioContext)
  //const routeParams = useParams<RouteProps>()
  //const broadcastChannel = new BroadcastChannel('policial_channel')
  //const { nomeUsuario, matricula } = makePortalIntegraAdapter()?.getUser()

  

  return (

<>
CONQUISTAS</>

  )
}

export default TrophiesPage
