import {  useState } from "react";
import { useAuth } from "../../../domain/contexts/auth-context";
import { useNavigate } from "react-router-dom";
import { LoginVO } from "../../../domain/models/dtos/loginVo";
import { toast } from "react-toastify";

export type LoginPageProps = {

}


const LoginPage: React.FC<LoginPageProps> = (props: LoginPageProps) => {
  const auth = useAuth()
  const navigate = useNavigate()

  const [userName, setUserName] = useState<string>()
  const [password, setPassword] = useState<string>()


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (userName?.length && password?.length) {
      const loginVO : LoginVO = { userName, password}
     auth.login(loginVO).then(loginOk => {
      console.log('Login ok',loginOk)
       if(loginOk)
        {
      toast.success('Bem vindo(a)!')
      navigate('/')
     }
     else{
      toast.error('Nome de usuário o senha incorretos')
     }
    }
    )
  
    }  
  }


  return (

    <div className="lt-xp-form-login">
      <div className="lt-xp-form-login-notification-area m-2">
        <img className="lt-logo-login"   src="../../../assets/literatour-sem-fundo.png" alt="Logo Literatour  sem fundo" />
        <h5 className="subtitle is-5">Portal do Assinante</h5>



        <div className="title is-5">Autentique-se para continuar</div>
      </div>
      <div className="lt-xp-form-login-input-area">
        <div className="columns m-2">
          <div className="column">
            <div className="field">
              <label className="label">E-mail de cadastro</label>
              <div className="control">
                <input type="text" onChange={(event: any) => setUserName(event.target.value)} value={userName} className="input" placeholder="O mesmo da assinatura" />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">Senha</label>
              <div className="control">
                <input type="password" onChange={(event: any) => setPassword(event.target.value)} value={password} className="input" placeholder="Caso seja seu primeiro acesso, clique em 'Esqueci a Senha'" />
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='lt-xp-form-login-button-area'>
        <div className="field is-grouped mt-4 ml-2 pt-4">
          <div className="control">
            <button onClick={(e) => handleSubmit(e)} className="button is-link">Acessar</button>
          </div>
          <div className="control">
            <button  onClick={()=> navigate('/esqueci-a-senha')} className="button is-link is-light">Esqueci a Senha</button>
          </div>
        </div>
      </div>

    </div>

  )
}


export default LoginPage
