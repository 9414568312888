// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.rambla-regular {
  font-family: "Rambla", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rambla-bold {
  font-family: "Rambla", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.rambla-regular-italic {
  font-family: "Rambla", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.rambla-bold-italic {
  font-family: "Rambla", sans-serif;
  font-weight: 700;
  font-style: italic;
}

/** Anotando os defaults sobrescritos do bulma como importantes */
.is-primary
{
    background-color: #ff8000 !important; 

}
.lt-xp-header 
{
  background-image: linear-gradient(to right top, #cc6600, #d56b00, #df6f00, #e87400, #f27900, #f67e06, #fb820c, #ff8712, #ff8c1c, #ff9024, #ff952c, #ff9933);
}

.is-dark
{
 background-color:  #54433B !important
}
 
@media screen and (max-width: 867px) {

#lt-header-menu-items  span.lt-menu-item-description {
  
  display: none;

}
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,iEAAiE;AACjE;;IAEI,oCAAoC;;AAExC;AACA;;EAEE,2JAA2J;AAC7J;;AAEA;;CAEC;AACD;;AAEA;;AAEA;;EAEE,aAAa;;AAEf;AACA","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n.rambla-regular {\n  font-family: \"Rambla\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.rambla-bold {\n  font-family: \"Rambla\", sans-serif;\n  font-weight: 700;\n  font-style: normal;\n}\n\n.rambla-regular-italic {\n  font-family: \"Rambla\", sans-serif;\n  font-weight: 400;\n  font-style: italic;\n}\n\n.rambla-bold-italic {\n  font-family: \"Rambla\", sans-serif;\n  font-weight: 700;\n  font-style: italic;\n}\n\n/** Anotando os defaults sobrescritos do bulma como importantes */\n.is-primary\n{\n    background-color: #ff8000 !important; \n\n}\n.lt-xp-header \n{\n  background-image: linear-gradient(to right top, #cc6600, #d56b00, #df6f00, #e87400, #f27900, #f67e06, #fb820c, #ff8712, #ff8c1c, #ff9024, #ff952c, #ff9933);\n}\n\n.is-dark\n{\n background-color:  #54433B !important\n}\n \n@media screen and (max-width: 867px) {\n\n#lt-header-menu-items  span.lt-menu-item-description {\n  \n  display: none;\n\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
